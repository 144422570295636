<template>
  <!-- 客服 -->
<div class="Customer" v-show="false">
  <div class="content" v-show="disappea">
    <div class="dev_p" @click="disappea = index">&#10006;</div>
    <!-- <button class="button" @click="disa = inde">在线咨询</button> -->
    <a class="button" href="tencent://message/?uin=3408363495">QQ咨询</a>
  </div>
  <!-- <div class="plate" v-show="disa">32321</div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      disappea: 1,
      index: 0,
      disa: 0,
      inde: 1,
    };
  },
};
</script>

<style scoped lang="less">
 .Customer {
   width: 100%;
   position: fixed;
   top: 300px;
   left: 0px;
 }
.content {
  width: 115px;
  height: 230px;
  font-size: 40px;
  color: #000;
  float: right;
  margin-right: 30px;
  background-image: url("../assets/imgs/hgyu.png");
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  z-index: 10000000000;

}

.button {
  display: inline-block;
  width: 100px;
  height: 30px;
  margin-bottom: 20px;
  border: 1px solid #ffffff;
  color: #ffffff;
  text-align: center;
  line-height: 30px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 2px;
  border-radius: 5px;
  letter-spacing: 2px;
  outline: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
.button:hover {
  background: #ffffff;
  color: #000;
}
.dev_p {
  font-size: 15px;
  width: 15px;
  height: 15px;
  margin-left: 100px;
  color: #ccc;
  cursor: pointer;
}
.plate {
  width: 200px;
  height: 400px;
  background: olivedrab;
  float: right;
  margin: 200px 20px 0 0;
}
</style>
