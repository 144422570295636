<template>
  <div class="index">
    <!-- 公用头部 -->
    <Cuheader />
    <!-- 客服 -->
    <Customer />
    <!-- 内容 -->
    <router-view></router-view>
    <!-- 公用底部 -->
    <Cufooter />
  </div>
</template>
<script>
import Cuheader from "@/components/header.vue";
import Cufooter from "@/components/footer.vue";
import Customer from "@/components/Customer.vue";
export default {
  components: { Cuheader, Cufooter, Customer },
  data() {
    return {};
  },
};
</script>

